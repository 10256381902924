import React from "react";

const ContactItem = ({ icon, children, name }) => {
  return (
    <div className="mt-1 flex items-center text-xs md:text-sm font-light">
      {icon &&
        React.cloneElement(icon, {
          className:
            "section-headline--icon text-white text-base md:text-lg font-light",
        })}
      <h3
        id={name || undefined}
        name={name}
        className={`${icon ? "ml-1" : ""} print:text-black`}
      >
        <span className="hidden print:inline text-black font-bold">
          {name}:{" "}
        </span>
        {children}
      </h3>
    </div>
  );
};

export default ContactItem;
