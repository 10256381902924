import React from "react";
import tw, { styled } from "twin.macro";

const Container = styled.div(({ maxCols, items }) => [
  tw`block`,
  tw`md:grid`,
  "gap: 1rem;",
  `grid-template-columns: repeat(
    ${Math.min(maxCols, items)},
    1fr
  );`,
]);

const Grid = ({ children, maxCols = 3, className }) => {
  return (
    <Container
      maxCols={maxCols}
      items={React.Children.count(children)}
      className={className}
    >
      {children}
    </Container>
  );
};

export default Grid;
