import React from "react";

const SimpleBox = ({ title, children }) => {
  return (
    <div className="section not-first:mt-2 testing-class-name text-base md:text-lg">
      <div className="section-headline flex items-center text-white font-light print:border-b-2 print:text-center">
        <h3 className="w-full">{title}</h3>
      </div>
      <div className="section-content section-content--plain text-xs md:text-sm">
        {children}
      </div>
    </div>
  );
};

export default SimpleBox;
