import React from "react";
import tw, { css } from "twin.macro";

const linkStyles = css`
  color: inherit;
  cursor: position;
  ${tw`no-underline`}
  &:visited {
    color: inherit;
  }
`;

const Project = ({ name, description, platform, url, showUrl = false }) => {
  return (
    <a
      href={url}
      className="section-content--item block mt-1.5"
      css={[url !== undefined && linkStyles]}
    >
      <span className="section-content--header block text-lg font-medium leading-none">
        {name}
      </span>
      <span className="section-content--subheader text-sm block font-normal leading-none">
        {platform}
      </span>
      <span className="section-content--text block text-xs leading-none">
        {description}
      </span>
      {showUrl && (
        <span className="section-content--text block text-xs leading-none">
          {url}
        </span>
      )}
    </a>
  );
};

export default Project;
