import React from "react";

const SkillItem = ({ name, level, isLast }) => {
  return (
    <div className="p-1 border border-solid border-white text-xs md:text-sm font-light text-white print:p-0 print:border-none print:not-first:ml-0.5 print:leading-none">
      {name}
      {!isLast && <span className="hidden print:inline">,</span>}
    </div>
  );
};

export default SkillItem;
