// import { Link } from "gatsby"
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { IoBriefcase } from "react-icons/io5";
import { GiGraduateCap } from "react-icons/gi";
import {
  FaHeart,
  FaCity,
  FaEnvelope,
  FaPhone,
  FaGithubAlt,
  FaLinkedinIn,
} from "react-icons/fa";
import { FiCode } from "react-icons/fi";
import FormalBox from "../components/FormalBox";
import FormalExperience from "../components/FormalExperience";
import FormalEducation from "../components/FormalEducation";
import Grid from "../components/Grid";
import Project from "../components/Project";
import SimpleBox from "../components/SimpleBox";
import ContactItem from "../components/ContactItem";
import Flex from "../components/Flex";
import SkillItem from "../components/SkillItem";

export default function Resume() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allDataYaml {
        edges {
          node {
            birth {
              year
              location
            }
            name {
              first
              middle
              last
            }
            about
            knowledge
            position
            experience {
              company
              position
              timeperiod
              location
              description
              website
              outcomes
            }
            education {
              school
              degree
              timeperiod
              gpa
              location
              website
            }
            projects {
              description
              name
              platform
              url
            }
            contributions {
              description
              name
              url
            }
            contact {
              city
              country
              email
              github
              phone
              linkedin
            }
            skillsGroupOrder
            skills {
              level
              name
              group
            }
          }
        }
      }
    }
  `);

  const person = data.allDataYaml.edges[0].node;

  if (!person) return null;

  // group skills by group and sort them by group order
  const groupedSkills = person.skills.reduce((acc, skill) => {
    if (!acc[skill.group]) {
      acc[skill.group] = [];
    }
    acc[skill.group].push(skill);
    return acc;
  });
  const orderedSkills = person.skillsGroupOrder
    .map(group => {
      return {
        group,
        skills: groupedSkills[group],
      };
    })
    .filter(group => group.skills);

  return (
    <main className="resume-cool block font-roboto max-w-5xl mx-auto">
      <div className="resume-cool--page max-w-full mx-auto">
        <header className="resume-cool--banner bg-banner-color text-white py-6 lg:py-8 px-2 sm:px-6 lg:px-8">
          <h1 className="resume-cool--banner-fullname text-xl lg:text-2xl leading-tight lg:leading-normal print:text-3xl print:text-center">
            {person.name.first} {person.name.middle} {person.name.last}
          </h1>
          <h3 className="resume-cool--banner-position text-sm lg:text-base font-light leading-none lg:leading-5 print:hidden">
            {person.position}
          </h3>
        </header>
        <div className="resume-cool--content grid grid-cols-1 grid-rows-2 md:grid-cols-12 md:grid-rows-1 print:block">
          <div className="resume-cool--content-left relative row-start-2 md:row-start-1 bg-accent-color text-secondary md:col-start-1 md:col-end-4 py-6 px-2 sm:px-6 lg:px-8 sm:pt-20 print:pt-0">
            <img
              src="/profile-pic.jpeg"
              className="resume-cool--profile-picture hidden md:block absolute top-0 right-0 transform -translate-y-1/3 translate-x-1/2 w-32 h-32 rounded-full border-4 border-accent-color print:hidden"
            />
            <SimpleBox title="Personal Info">
              <ContactItem icon={<FaCity />} name="Address">
                {person.contact.city}, {person.contact.country}
              </ContactItem>
              <ContactItem icon={<FaEnvelope />} name="Email address">
                <a href={`mailto:${person.contact.email}`}>
                  {person.contact.email}
                </a>
              </ContactItem>
              <ContactItem icon={<FaPhone />} name="Phone number">
                <a href={`tel:${person.contact.phone}`}>
                  {person.contact.phone}
                </a>
              </ContactItem>
              <ContactItem icon={<FaGithubAlt />} name="Github account">
                <a
                  target="_blank"
                  href={`https://github.com/${person.contact.github}`}
                >
                  {person.contact.github}
                </a>
              </ContactItem>
              <ContactItem icon={<FaLinkedinIn />} name="Linkedin account">
                <a target="_blank" href={person.contact.linkedin}>
                  {person.contact.linkedin.split("/").pop()}
                </a>
              </ContactItem>
            </SimpleBox>
            <SimpleBox title="Skills">
              <div className="print:pt-1">
                {orderedSkills.map(({ group, skills }) => (
                  <>
                    <h3 className="block pt-2 text-white font-bold text-sm print:hidden">
                      {group}:{" "}
                    </h3>
                    <Flex
                      key={group}
                      className="mt-1 print:px-2.5 print:gap-0 print:items-baseline print:mt-0 "
                    >
                      <h3 className="hidden text-white font-bold text-sm print:block print:text-base print:m-0 print:leading-none">
                        {group}:{" "}
                      </h3>
                      {skills.map((skill, index) => (
                        <SkillItem
                          key={skill.name}
                          {...skill}
                          isLast={skills.length - 1 === index}
                        />
                      ))}
                    </Flex>
                  </>
                ))}
              </div>
            </SimpleBox>
          </div>
          <div className="resume-cool--content-right md:col-start-4 md:col-end-13 py-6 px-2 sm:px-6 lg:px-8 sm:pt-20 print:pt-0 print:break-before-all">
            <FormalBox icon={<IoBriefcase />} title="Work experience">
              {person.experience.map((experience, index) => (
                <FormalExperience {...experience} key={index} />
              ))}
            </FormalBox>
            <FormalBox icon={<GiGraduateCap />} title="Education">
              {person.education.map((education, index) => (
                <FormalEducation {...education} key={index} />
              ))}
            </FormalBox>
            <FormalBox icon={<FiCode />} title="Projects">
              <Grid>
                {person.projects.map(project => (
                  <Project key={project.name} {...project} />
                ))}
              </Grid>
            </FormalBox>
            <FormalBox icon={<FaHeart />} title="Contributions">
              <Grid maxCols={2}>
                {person.contributions.map(project => (
                  <Project {...project} key={project.name} showUrl />
                ))}
              </Grid>
            </FormalBox>
          </div>
        </div>
      </div>
    </main>
  );
}
