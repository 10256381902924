import React from "react";
import tw, { css } from "twin.macro";

const linkStyles = css`
  color: inherit;
  cursor: position;
  ${tw`no-underline`}
  &:visited {
    color: inherit;
  }
`;

const FormalEducation = ({
  school,
  degree,
  timeperiod,
  gpa,
  location,
  website,
}) => {
  return (
    <a
      href={website}
      className="section-content--item block mt-1.5"
      css={[website !== undefined && linkStyles]}
    >
      <span className="section-content--header block text-lg font-medium leading-none">
        {degree}
      </span>
      <span className="section-content--subheader block font-normal leading-none">
        {school}
      </span>
      <div className="section-content--text block text-xs leading-none">
        <div>{timeperiod}</div>
        <div>{location}</div>
      </div>
      <span className="section-content--text block text-xs leading-none">
        Major GPA: {gpa}
      </span>
    </a>
  );
};

export default FormalEducation;
